import { PartyField } from "@/shared/components/fields/PartyField"
import {
  faCalendar,
  faChevronCircleDown,
  faClock,
  faHashtag,
  faList,
  faMoneyCheckDollar,
  faNoteSticky,
  faPartyHorn,
  faPhone,
  faRotate,
  faSlider,
  faSlidersSimple,
  faTags,
  faText,
  faToggleOff,
  faUserCrown,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import {
  AgreementOwnerField,
  DateField,
  DateRangeField,
  ExtendedTaskField,
  FTEField,
  MoneyField,
  MultiDateRangeField,
  MultiSelectField,
  NumberField,
  PhoneField,
  PriceRangeField,
  PriceSelectorField,
  SelectField,
  SliderField,
  TagsField,
  TextAreaField,
  TextField,
  ToggleField,
  parseAgreementOwnerField,
  parseDateField,
  parseDateRangeField,
  parseExtendedTaskField,
  parseFTEField,
  parseMoneyField,
  parseMultiDateRangeField,
  parseMultiSelectField,
  parseNumberField,
  parsePhoneField,
  parsePriceRangeField,
  parsePriceSelectorField,
  parseSelectField,
  parseSliderField,
  parseTagsField,
  parseTextAreaField,
  parseTextField,
  parseToggleField,
} from "../components/fields"
import { IFieldEntry } from "../registry"
import { FIELD_TYPES } from "../utils/constants"

export const defaultRegistry: IFieldEntry[] = [
  {
    key: "text",
    type: FIELD_TYPES.TEXT,
    label: "Text",
    description: "A single-line text input field.",
    categories: ["General", "Text"],
    icon: faText,
    component: TextField,
    parse: parseTextField,
  },
  {
    key: "textarea",
    type: FIELD_TYPES.TEXTAREA,
    label: "Text Area",
    description: "A multi-line text input field.",
    categories: ["General", "Text"],
    icon: faNoteSticky,
    component: TextAreaField,
    parse: parseTextAreaField,
  },
  {
    key: "number",
    type: FIELD_TYPES.NUMBER,
    label: "Number",
    description: "A single number input field.",
    categories: ["General", "Number"],
    icon: faHashtag,
    component: NumberField,
    parse: parseNumberField,
  },
  {
    key: "date",
    type: FIELD_TYPES.DATE,
    label: "Date",
    description: "A a single date input field.",
    categories: ["General", "Date"],
    icon: faCalendar,
    component: DateField,
    parse: parseDateField,
  },
  {
    key: "date_range",
    type: FIELD_TYPES.DATE_RANGE,
    label: "Date range",
    description: "A range between two dates.",
    categories: ["General", "Date"],
    icon: faCalendar,
    component: DateRangeField,
    parse: parseDateRangeField,
  },
  {
    key: "multi_date_range",
    type: FIELD_TYPES.MULTI_DATE_RANGE,
    label: "Multi date range",
    description: "A multi date range.",
    categories: ["General", "Date"],
    icon: faCalendar,
    component: MultiDateRangeField,
    parse: parseMultiDateRangeField,
  },
  {
    key: "money",
    type: FIELD_TYPES.MONEY,
    label: "Money input",
    description: "A number money input with currency selector.",
    categories: ["General", "Pricings", "Number"],
    icon: faChevronCircleDown,
    component: MoneyField,
    parse: parseMoneyField,
  },
  {
    key: "select",
    type: FIELD_TYPES.SELECT,
    label: "Select",
    description: "A dropdown field with a single value.",
    categories: ["General", "Select"],
    icon: faList,
    component: SelectField,
    parse: parseSelectField,
  },
  {
    key: "multi_select",
    type: FIELD_TYPES.MULTISELECT,
    label: "Multi select",
    description: "A dropdown field with multiple values.",
    categories: ["General", "Select"],
    icon: faList,
    component: MultiSelectField,
    parse: parseMultiSelectField,
  },
  {
    key: "phone",
    type: FIELD_TYPES.PHONE,
    label: "Phone no.",
    description: "A phone no. input field.",
    categories: ["General", "Misc", "Text"],
    icon: faPhone,
    component: PhoneField,
    parse: parsePhoneField,
  },
  {
    key: "price_selector",
    type: FIELD_TYPES.PRICE_SELECTOR,
    label: "Price cards",
    description: "Show pricings as selectable cards.",
    categories: ["General", "Pricings"],
    icon: faMoneyCheckDollar,
    component: PriceSelectorField,
    parse: parsePriceSelectorField,
  },
  {
    key: "price_range",
    type: FIELD_TYPES.PRICE_RANGE,
    label: "Price range",
    description: "A range between to money inputs.",
    categories: ["General", "Pricings", "Number"],
    icon: faSlidersSimple,
    component: PriceRangeField,
    parse: parsePriceRangeField,
  },
  {
    key: "agreement_owner",
    type: FIELD_TYPES.AGREEMENT_OWNER,
    label: "Agreement owner",
    description: "Company who owns the task",
    categories: ["General", "Misc"],
    icon: faUserCrown,
    component: AgreementOwnerField,
    parse: parseAgreementOwnerField,
  },
  {
    key: "fte",
    type: FIELD_TYPES.FTE,
    label: "FTE",
    description: "Calculate number of full-time employees.",
    categories: ["General", "Misc", "Text"],
    icon: faClock,
    component: FTEField,
    parse: parseFTEField,
  },
  {
    key: "extended_task",
    type: FIELD_TYPES.EXTENDED_TASK,
    label: "Extended task",
    description: "Show if task is extended",
    categories: ["General", "Date"],
    icon: faRotate,
    component: ExtendedTaskField,
    parse: parseExtendedTaskField,
  },
  {
    key: "tags",
    type: FIELD_TYPES.TAGS,
    label: "Tags",
    description: "Add tags to an entity",
    categories: ["General", "Misc"],
    icon: faTags,
    component: TagsField,
    parse: parseTagsField,
  },
  {
    key: "slider",
    type: FIELD_TYPES.SLIDER,
    label: "Slider",
    description: "0-100 Range slider",
    categories: ["General", "Number"],
    icon: faSlider,
    component: SliderField,
    parse: parseSliderField,
  },
  {
    key: "toggle",
    type: FIELD_TYPES.TOGGLE,
    label: "Toggle",
    description: "On/Off toggle",
    categories: ["General", "Toggle"],
    icon: faToggleOff,
    component: ToggleField,
    parse: parseToggleField,
  },
  {
    key: "party",
    type: FIELD_TYPES.PARTY,
    label: "Party",
    description: "Contract party",
    categories: ["General", "Misc"],
    icon: faPartyHorn,
    component: PartyField,
  },
]
