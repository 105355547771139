import { Icon } from "@/features/icons/components/IconLoader"
import { MoneyInput, formatValue } from "@/shared/components/inputs"
import { InfoItem } from "@/shared/components/items/InfoItem"
import { formatTypeValues } from "@/shared/utils/helper"
import { cn } from "@/utils/helpers"
import { faArrowRight } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { z } from "zod"
import { IFieldProps } from "../.."

const valueSchema = z.object({
  value: z.number(),
  currency: z.string(),
  locale: z.string(),
})

export const MoneyField = ({
  value,
  onChange,
  field,
  readOnly,
  oldEntry,
  error,
}: IFieldProps) => {
  const parsedValue = parseMoneyField(value)

  if (readOnly) {
    const newValue = formatValue(formatTypeValues(parsedValue, field.type))
    const oldValue = formatValue(
      formatTypeValues(parseMoneyField(oldEntry?.[field.key]), field.type),
    )
    return (
      <InfoItem
        title={field.label}
        description={
          <div className="flex items-center gap-2 text-xs">
            {oldEntry && oldValue !== newValue && (
              <>
                <span className="text-red-600">
                  {formatValue(
                    formatTypeValues(
                      parseMoneyField(oldEntry?.[field.key]),
                      field.type,
                    ),
                  )}
                </span>
                {oldEntry && (
                  <Icon
                    icon={faArrowRight}
                    size="xs"
                    className="text-custom-gray-dark"
                  />
                )}
              </>
            )}
            <span
              className={cn(
                "",
                oldEntry && oldValue !== newValue && "text-green-600",
              )}
            >
              {typeof parsedValue === "string"
                ? parsedValue
                : formatValue(formatTypeValues(parsedValue, field.type)) ??
                  "Not defined"}
            </span>
          </div>
        }
      />
    )
  }
  return (
    <MoneyInput
      {...field}
      className="field-input"
      value={formatTypeValues(parsedValue, field.type)}
      onChange={(value) => onChange(value)}
      error={error}
    />
  )
}

export const parseMoneyField = (
  value: any,
): { value: number; locale: string; currency: string } | string => {
  const check = valueSchema.safeParse(value)
  if (!check.success) {
    return "Invalid value"
  }
  return value
}
